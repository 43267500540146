import { ReactComponent as Icon1 } from "../assets/svgs/Direct Mentorship.svg";
import { ReactComponent as Icon2 } from "../assets/svgs/Comprehensive Resource Access.svg";
import { ReactComponent as Icon3 } from "../assets/svgs/Branding.svg";
import { ReactComponent as Icon4 } from "../assets/svgs/Robust Infrastructure Support.svg";

import access1 from "../assets/images/1.webp";
import access2 from "../assets/images/2.webp";
import access3 from "../assets/images/3.webp";
import access4 from "../assets/images/4.webp";
import access5 from "../assets/images/5.webp";
import access6 from "../assets/images/6.webp";

export const roadmap = [
  {
    id: 1,
    title: "CRM Setup",
    desc: "Systemization Stage: Implement a CRM system tailored to manage customer relationships effectively from day one.",
  },
  {
    id: 2,
    title: "Developing Your AI Company Website",
    desc: "Relationship Building: Meet your dedicated Client Manager, who will be your main point of contact to guide you throughout your business journey",
  },
  {
    id: 3,
    title: "Client Manager Introduction",
    desc: "Digital Presence: Collaborate with our team to create a professional website that showcases your AI solutions, driving engagement and attracting clients.",
  },
  {
    id: 4,
    title: "Marketing Manager Connection",
    desc: "Brand Awareness : Connect with a Marketing Manager to start crafting and executing marketing strategies for growth and engagement",
  },
  {
    id: 5,
    title: " Branding Manager Connection",
    desc: "Identity Formation: Work with a Branding Manager to develop a unique brand identity, making you stand out in your market",
  },
  {
    id: 6,
    title: "Daily Task Management",
    desc: "Productivity Enhancement: Implement systems to help you track daily tasks, prioritize actions, and manage projects efficiently.",
  },
  {
    id: 7,
    title: "Service Training Sessions",
    desc: "Skill Building: Gain in-depth knowledge of each service you offer through training sessions that prepare you and your team.",
  },
  {
    id: 8,
    title: "Technical Support for Deals",
    desc: "Sales Enablement: Receive technical support that helps close deals, handle objections, and ensure clients understand the value you deliver",
  },
  {
    id: 9,
    title: "Sales Strategy Planning",
    desc: "Revenue Optimization: Develop a tailored sales strategy to boost your client acquisition and optimize your revenue streams.",
  },
  {
    id: 10,
    title: "High-End Deal Closure Support",
    desc: "Final Push: Get expert assistance to help finalize and close large, high-value deals for maximum impact.",
  },
  {
    id: 11,
    title: "Consistent Mentorship",
    desc: "Growth Coaching : Regular mentorship sessions keep you on track and provide guidance to handle business challenges.",
  },
  {
    id: 12,
    title: "Ongoing Business Growth",
    desc: "Sustained Success: Maintain growth momentum with continued access to strategies, tools, and insights tailored to scale your business",
  },
];

export const subscriptionBenefits = [
  {
    id: 1,
    title: "Direct Mentorship",
    description:
      "Gain direct access to mentorship from Mahin B S, guiding you through the intricacies of building and managing your AI-focused business.",
    icon: Icon1,
  },
  {
    id: 2,
    title: "Comprehensive Resource Access",
    description:
      "Leverage a vast array of specialized resources, tools, and the expertise of over 170+ professionals dedicated to delivering 23 AI and IT services tailored to your clients' needs.",
    icon: Icon2,
  },
  {
    id: 3,
    title: "Branding",
    description:
      "Branding is the process of creating and disseminating the brand name, its qualities and personality. Branding could be applied to the entire corporate identity as well as to individual products and services or concepts.",
    icon: Icon3,
  },
  {
    id: 4,
    title: "Robust Infrastructure Support",
    description:
      "Benefit from a strong infrastructure that enables you to efficiently deliver AI solutions, ensuring your business runs smoothly and effectively meets client demands",
    icon: Icon4,
  },
];

export const aiExpertAccesses = [
  { title: "Client Manager", img: access1 },
  { title: "Web Developer", img: access2 },
  { title: "Marketing Manager", img: access3 },
  { title: "Market Training", img: access4 },
  { title: "Branding Team", img: access5 },
  { title: "Technical Team", img: access6 },
];

// services
export const services = [
  {
    id: 0,
    title: "UX/UI",
    desc: "Put your customers first with our intuitive and user-friendly interfaces. Our UX design services enhance customer satisfaction and create delightful interactions.",
    img: require("../assets/images/serviceimg/uiux.jpg"),
    link: "",
  },
  {
    id: 1,
    title: "Mobile App Development",
    desc: "Designing and developing applications for mobile devices, enabling businesses to reach and engage with their customers on the go.",
    img: require("../assets/images/serviceimg/mob.jpg"),
    link: "",
  },
  {
    id: 2,
    title: "Web Development (Full stack)",
    desc: "Building websites and web applications using programming languages, facilitating online presence and seamless user experiences.",
    img: require("../assets/images/serviceimg/web.jpg"),
    link: "",
  },
  {
    id: 3,
    title: "Cloud Computing Services",
    desc: "Providing scalable and flexible cloud-based infrastructure and services, allowing businesses to store, process, and analyze data efficiently.",
    img: require("../assets/images/serviceimg/cloud.jpg"),
    link: "",
  },
  {
    id: 4,
    title: "Artificial Intelligence Development",
    desc: "Developing AI systems that possess human-like intelligence, enabling automation, personalization, and advanced decision-making.",
    img: require("../assets/images/serviceimg/ai.jpg"),
    link: "",
  },
  {
    id: 5,
    title: "Data Analytics and Business Intelligence",
    desc: "Collecting and analyzing data to gain actionable insights, helping businesses to make data-driven decisions and optimize processes.",
    img: require("../assets/images/serviceimg/data.jpg"),
    link: "",
  },
  {
    id: 6,
    title: "Internet of Things (IoT) Development",
    desc: "Building applications and systems that connect physical devices, allowing data exchange and enabling smart and interconnected environments.",
    img: require("../assets/images/serviceimg/iot.jpg"),
    link: "",
  },
  {
    id: 7,
    title: "Virtual Reality (VR) and Augmented Reality (AR) Development",
    desc: "Creating immersive experiences that merge virtual and real-world elements, revolutionizing industries like gaming, education, and training.",
    img: require("../assets/images/serviceimg/vr.jpg"),
    link: "",
  },
  {
    id: 8,
    title: "Blockchain Development",
    desc: "Implementing decentralized and secure distributed ledger technology, enabling transparent transactions, digital identity management, and trustless systems.",
    img: require("../assets/images/serviceimg/block.jpg"),
    link: "",
  },
  {
    id: 9,
    title: "Game Development",
    desc: "Designing and developing interactive and engaging games for various platforms, providing entertainment and immersive experiences to players.",
    img: require("../assets/images/serviceimg/game.jpg"),
    link: "",
  },
  {
    id: 10,
    title: "Chatbot Development",
    desc: "Building AI-powered conversational agents to automate customer interactions, improve customer service, and provide personalized assistance.",
    img: require("../assets/images/serviceimg/chat.jpg"),
    link: "",
  },
  {
    id: 11,
    title: "AI Calling Agency",
    desc: "Revolutionizing communication through AI-powered call solutions for businesses worldwide",
    img: require("../assets/images/serviceimg/aicall.jpg"),
    link: "https://boostmysites.vercel.app/",
  },
];

// subscription plans

export const sixMonthPlanDetails = {
  title: "6 Month",
  accordionDetails: [
    {
      heading: "Branding and Marketing Team",
      description: "Custom branding and marketing services.",
      services: [
        "Custom branding strategy, logo, business card design",
        "Social media management (Facebook, LinkedIn, Instagram)",
        "Paid ads, content marketing, SEO, and outreach",
        "Brand positioning to stand out in the market",
      ],
    },
    {
      heading: "Company Registration & CA Services",
      description:
        "Comprehensive company registration and compliance services.",
      services: [
        "Complete company registration (Pvt Ltd, LLP, etc.)",
        "GST registration and tax filing support",
        "Social media management (Facebook, LinkedIn, Instagram)",
        "Ongoing compliance and accounting services",
      ],
    },
    {
      heading: "Full-Stack Website Design",
      description: "Professional website design and development.",
      services: [
        "Custom-designed, mobile-responsive website",
        "SEO optimization for better visibility",
        "User-friendly interface and functionality (contact forms, e-commerce, etc.)",
      ],
    },
    {
      heading: "Full Support System",
      description: "Comprehensive support for business operations.",
      services: [
        "24/7 support for troubleshooting and guidance",
        "Network access to entrepreneurs, mentors, and experts",
        "Weekly check-ins with your mentor",
      ],
    },
    {
      heading: "Expert Mentoring",
      description: "Guidance from experienced mentors.",
      services: [
        "One-on-one business strategy sessions",
        "Insights into AI industry trends and opportunities",
        "Problem-solving and decision-making support",
        "Daily Scrutinizing & Task Management",
        "Daily actionable tasks and milestones",
        "Progress monitoring and weekly reviews",
        "Monthly goal setting and performance tracking",
      ],
    },
    {
      heading: "AI Training & Skill Development",
      description: "Hands-on training in AI technologies.",
      services: [
        "Fundamentals of AI, machine learning, and deep learning",
        "Hands-on workshops and coding training",
        "Tools and software for building AI products",
      ],
    },
    {
      heading: "Why Choose This Program?",
      description: "Reasons to select this program for business growth.",
      services: [
        "Affordable investment of ₹2.44 Lakhs for all-inclusive services",
        "Comprehensive support from company registration to daily business tasks",
        "Proven experience in helping entrepreneurs start and scale AI companies",
      ],
    },
  ],
  price: "₹2,44,000 + GST",
};

export const oneYearPlanDetails = {
  title: "1 Year",
  accordionDetails: [
    {
      heading: "Exclusive Direct personal mentorship",
      description: "Get unparalleled guidance from industry leaders",
      mentors: [
        "Mahin BS, (Founder & Chairman)",
        "Reshab, (CEO)",
        "Darshan, (COO)",
      ],
      additionalInfo:
        "Achieve your goals with personalized insights and strategies from the top minds in the field",
    },
    {
      heading: "Branding and Marketing Team",
      description: "Custom branding strategy, logo, business card design",
      services: [
        "Custom branding strategy, logo, business card design",
        "Social media management (Facebook, LinkedIn, Instagram)",
        "Paid ads, content marketing, SEO, and outreach",
        "Brand positioning to stand out in the market",
      ],
    },
    {
      heading: "Company Registration & CA Services",
      description: "Complete company registration (Pvt Ltd, LLP, etc.)",
      services: [
        "GST registration and tax filing support",
        "Social media management (Facebook, LinkedIn, Instagram)",
        "Ongoing compliance and accounting services",
      ],
    },
    {
      heading: "Full-Stack Website Design",
      description: "Custom-designed, mobile-responsive website",
      services: [
        "Custom-designed, mobile-responsive website",
        "SEO optimization for better visibility",
        "User-friendly interface and functionality (contact forms, e-commerce, etc.)",
      ],
    },
    {
      heading: "Full Support System",
      description: "24/7 support for troubleshooting and guidance",
      services: [
        "24/7 support for troubleshooting and guidance",
        "Network access to entrepreneurs, mentors, and experts",
        "Weekly check-ins with your mentor",
      ],
    },
    {
      heading: "Expert Mentoring",
      description: "One-on-one business strategy sessions",
      services: [
        "One-on-one business strategy sessions",
        "Insights into AI industry trends and opportunities",
        "Problem-solving and decision-making support",
        "Daily Scrutinizing & Task Management",
        "Daily actionable tasks and milestones",
        "Progress monitoring and weekly reviews",
        "Monthly goal setting and performance tracking",
      ],
    },
    {
      heading: "AI Training & Skill Development",
      description: "Fundamentals of AI, machine learning, and deep learning",
      services: [
        "Fundamentals of AI, machine learning, and deep learning",
        "Hands-on workshops and coding training",
        "Tools and software for building AI products",
      ],
    },
  ],
  price: "₹3,40,000 + GST",
};

export const twoYearPlanDetails = {
  title: "2 Years",
  accordionDetails: [
    {
      heading: "Exclusive Direct personal mentorship",
      desc: "Get unparalleled guidance from industry leaders:",
      mentors: [
        "Mahin BS (Founder & Chairman)",
        "Reshab (CEO)",
        "Darshan (COO)",
      ],
      additionalDesc:
        "Achieve your goals with personalized insights and strategies from the top minds in the field",
    },
    {
      heading: "Branding and Marketing Team",
      services: [
        "Custom branding strategy, logo, business card design",
        "Social media management (Facebook, LinkedIn, Instagram)",
        "Paid ads, content marketing, SEO, and outreach",
        "Brand positioning to stand out in the market",
      ],
    },
    {
      heading: "Company Registration & CA Services",
      desc: "Complete company registration (Pvt Ltd, LLP, etc.)",
      services: [
        "GST registration and tax filing support",
        "Ongoing compliance and accounting services",
      ],
    },
    {
      heading: "Full-Stack Website Design",
      services: [
        "Custom-designed, mobile-responsive website",
        "SEO optimization for better visibility",
        "User-friendly interface and functionality (contact forms, e-commerce, etc.)",
      ],
    },
    {
      heading: "Full Support System",
      services: [
        "24/7 support for troubleshooting and guidance",
        "Network access to entrepreneurs, mentors, and experts",
        "Weekly check-ins with your mentor",
      ],
    },
    {
      heading: "Expert Mentoring",
      services: [
        "One-on-one business strategy sessions",
        "Insights into AI industry trends and opportunities",
        "Problem-solving and decision-making support",
        "Daily Scrutinizing & Task Management",
        "Daily actionable tasks and milestones",
        "Progress monitoring and weekly reviews",
        "Monthly goal setting and performance tracking",
      ],
    },
    {
      heading: "AI Training & Skill Development",
      services: [
        "Fundamentals of AI, machine learning, and deep learning",
        "Hands-on workshops and coding training",
        "Tools and software for building AI products",
      ],
    },
  ],
  price: "₹4,80,000 + GST",
};
